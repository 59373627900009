.row {
  display: flex;
  clear: both;
  margin-top: 35px;

  .column {
    padding: 10px 10px 10px 5px;
    width: 25%;

    img {
      width: 95%;
      height: auto;
    }

    img:hover {
      transform: scale(1.1);
      transition: 0.5s;
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1)
    }
  }
}
