@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

html{
  scroll-behavior: smooth;
}


.font-cormo {
  font-family: "Cormorant Garamond", serif;
}

.font-sans {
  font-family: "Montserrat", serif;
}

.text-container {
  opacity: 0;
  animation: slideIn 2s ease forwards;
}

.text-container.show {
  opacity: 1;
  transform: translateY(0);
}

.hiddenAnimation {
  opacity: 0;
  transition-delay: 250ms;
  transform: translateY(20%);
  transition: linear 1s;
}
.showAnimation {
  opacity: 1;
  transform: translateY(0);
}

input:focus-visible {
  outline: none !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}