.backgroundHero {
  position: relative;
}

.backgroundImage {
  position: relative;
  width: 100%;
  height: 85vh;
  background-image: url("../assests/background1.jpg");
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(to bottom, rgba(0, 19, 35, 0.6),rgba(99, 33, 104, 0.6)); */
  background-image: linear-gradient(
    to bottom,
    rgba(114, 99, 47, 0.5),
    rgba(0, 19, 35, 0.6)
  );
  z-index: 1; /* Ensure the overlay is behind the text content */
}

.textContent {
  position: absolute;
  z-index: 2; /* Ensure the text content is on top of the overlay */
  color: white; /* Set text color */
}

.backgroundImageAbout {
  position: relative;
  width: 100%;
  height: 80vh;
  background-image: url("../assests/secondAboutus.webp");
  background-size: cover;
}

.linearGradient {
  background-image: linear-gradient(
    to bottom,
    rgba(51, 51, 51, 1),
    rgba(27, 27, 27, 0)
  );
  width: 350px;
  border: 1px solid rgba(255, 255, 255, 0.06);
}

.linearBorder {
  border: 1px solid rgba(255, 255, 255, 0.06);
}
.linearGradient2 {
  background-image: linear-gradient(
    to bottom,
    rgba(27, 27, 27, 0),
    rgba(27, 27, 27, 0),
    rgba(51, 51, 51, 1)
  );
  width: 350px;
}

.btnOutlineHoverFill {
  border: 1px solid #CCAD54;
  color: #CCAD54;
}

.btnOutlineHoverFill:hover {
  background-color: #CCAD54;
  color: #142E44;
}

@media (max-width: 600px) {
  .backgroundImage {
    width: 100%;
    height: 95vh;
  }

  .backgroundImageAbout {
    width: 100%;
    height: 45vh;
  }
}
