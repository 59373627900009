.embla__buttons {
    display: flex;
    width: 100%;
    justify-content: center;
}

.embla__controls {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.embla__button__svg {
    color: white;
    width: 1rem;
    height: 1rem;
}
